* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* default */
  /* background-color: rgb(33, 33, 33);
  color: #ffffff; */
  overflow-x: hidden;
  font-family: 'Roboto', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100vh;
}

#root > div {
  min-height: 100%;
}

a {
  cursor: pointer;
  color: currentColor;
  text-decoration: none;
}
